<template>
  <div>
    <SmallNav />
    <div class="holder">
      <div class="container pt-3">
        <div class="safe_text_holder">
          <h4 class="safe_text">
            <img src="../assets/images/safe.svg" alt="safe" />
            <span @click="back"> Back</span>
          </h4>
          <div class="projects_link">
            <router-link to="/dashboard/safeprojects">
              <h6 class="btn">
                <img src="../assets/images/house-safe.svg" alt="projects" />
                Projects
              </h6>
            </router-link>
          </div>
        </div>

        <div class="form_holder">
          <!-- <div class="project_info_text">
            <h5 class="">
              Project Information
            </h5>
          </div>
          <hr /> -->

          <div class="container">
            <div class="row">
              <div class="col-md-8">
                <div class="row">
                  <div class="col stepper">
                    <div
                      class="stepper-1"
                      :class="[activeStepper === 'active' ? 'activeStep' : '']"
                    ></div>
                    <h5
                      :class="[
                        activeStepper === 'active' ? 'activeStepText' : ''
                      ]"
                      @click="nextStep"
                    >
                      Step 1
                    </h5>
                    <h6
                      :class="[
                        activeStepper === 'active' ? 'activeStepText' : ''
                      ]"
                      @click="nextStep"
                    >
                      Project information
                    </h6>
                  </div>
                  <div class="col stepper">
                    <div
                      class="stepper-2"
                      :class="[
                        activeStepper2 === 'active2' ? 'activeStep' : ''
                      ]"
                    ></div>
                    <h5
                      :class="[
                        activeStepper2 === 'active2' ? 'activeStepText' : ''
                      ]"
                    >
                      Step 2
                    </h5>
                    <h6
                      :class="[
                        activeStepper2 === 'active2' ? 'activeStepText' : ''
                      ]"
                    >
                      Project funding
                    </h6>
                  </div>
                </div>
                <form v-if="step === 1">
                  <div class="form-group">
                    <label for="type">Project Type</label>
                    <select
                      class="form-control"
                      id="type"
                      v-model="projecttypeid"
                    >
                      <option :value="null" selected disabled
                        >--Select--</option
                      >
                      <option
                        v-for="(item, index) in projectTypes"
                        :key="index"
                        :value="item.id"
                        >{{ item.name }}</option
                      >
                      <option value="0">Others</option>
                    </select>
                  </div>
                  <div class="form-group" v-if="projecttypeid == '0'">
                    <label for="specify">Specify the project type</label>
                    <input
                      type="text"
                      class="form-control"
                      id="specify"
                      name="title"
                      v-model="specifytype"
                      placeholder="Specify the project type"
                    />
                  </div>
                  <div class="form-group">
                    <label for="type"
                      >Do you want to add project handler?</label
                    >
                    <select
                      @change="revertHandler"
                      class="form-control"
                      id="handler"
                      v-model="requestHandlerOption"
                    >
                      <option selected disabled :value="null"
                        >--Select--</option
                      >
                      <option value="yes">Yes</option>
                      <option value="no">No</option>
                    </select>
                  </div>

                  <div
                    class="form-group panel-input"
                    v-if="requestHandlerOption == 'yes'"
                  >
                    <label for="">Project Handler</label>
                    <!-- @focusin="runOption" -->
                    <!-- @click="runOption($event)" -->
                    <input
                      @focus="runOption($event)"
                      ref="openPanel"
                      type="text"
                      class="form-control"
                      id="panel"
                      name="title"
                      v-model="prohandler"
                      placeholder="Select project handler or search for one"
                    />
                    <span @click="openOption = false" v-if="openOption">
                      <i class="fa fa-chevron-up"></i>
                    </span>
                    <span @click="openOption = true" v-if="!openOption">
                      <i class="fa fa-chevron-down"></i>
                    </span>
                    <div v-if="openOption" class="option-list">
                      <div
                        class="handler-list"
                        v-for="(item, index) in partners"
                        :key="index"
                      >
                        <li
                          :class="[
                            item.firstname === prohandler ? 'activeHandler' : ''
                          ]"
                          @click="selectPanel(item.firstname, item.id)"
                        >
                          {{ item.firstname }}
                        </li>
                      </div>
                      <div class="add-project-handler-trigger">
                        <p data-toggle="modal" data-target="#addprojecthandler">
                          <small>+</small> Add project handler
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="form-group" v-if="requestHandlerOption == 'yes'">
                    <label for="type">Project</label>
                    <select
                      class="form-control"
                      id="handler"
                      v-model="handlerServiceId"
                    >
                      <option selected disabled :value="null"
                        >--Select--</option
                      >
                      <option
                        v-for="(item, index) in servicesPartner"
                        :key="index"
                        :value="item.id"
                        >{{ item.service }}</option
                      >
                      <!-- <option value="no">No</option> -->
                    </select>
                  </div>

                  <div class="form-group">
                    <label for="projectdetails">Project Details</label>
                    <textarea
                      type="text"
                      class="form-control pro_details"
                      id="projectdetails"
                      rows="5"
                      v-model="projectdetails"
                      placeholder="Enter a detailed details of your project"
                    >
                    </textarea>
                  </div>
                </form>
                <form v-if="step === 2">
                  <div class="form-group">
                    <label for="title">Project Title</label>
                    <input
                      type="text"
                      class="form-control"
                      id="title"
                      name="title"
                      placeholder="Enter project title"
                      v-model="title"
                      maxlength="20"
                    />
                  </div>

                  <div class="form-group">
                    <label for="">Allocation/Amount/Budget</label>
                    <div class="allocation_wrap">
                      <div class="text-justify radio_wrap-2">
                        <div class="form-check form-check-inline">
                          <input
                            class="form-check-input"
                            type="radio"
                            name="allocation"
                            id="fixed"
                            v-model="amountType"
                            value="fixed"
                          />
                          <label
                            class="form-check-label radio-label"
                            for="fixed"
                            >Fixed Amount</label
                          >
                        </div>
                        <div class="form-check form-check-inline">
                          <input
                            v-model="amountType"
                            class="form-check-input"
                            type="radio"
                            name="allocation"
                            id="range"
                            value="range"
                          />
                          <label
                            class="form-check-label radio-label"
                            for="range"
                            >Range</label
                          >
                        </div>
                      </div>

                      <div
                        class="form-group pl-4 pr-4"
                        v-if="amountType === 'fixed'"
                      >
                        <!-- onfocus="(this.type='text')" -->
                        <input
                          ref="fixamount"
                          onfocus="(this.type='text')"
                          oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                          class="form-control"
                          id="fixamount"
                          name="fixedamount"
                          placeholder="Fixed Amount"
                          v-model="amountforfixed2"
                          maxlength="11"
                        />
                      </div>
                      <div
                        class="form-group range_input pl-4 pr-4"
                        v-if="amountType === 'range'"
                      >
                        <input
                          onfocus="(this.type='text')"
                          oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                          class="form-control"
                          id="minrange"
                          name="start"
                          placeholder="Minimun"
                          v-model="minamountforrange2"
                          maxlength="11"
                        />
                        <input
                          onfocus="(this.type='text')"
                          oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                          class="form-control"
                          id="maxrange"
                          name="ends"
                          placeholder="Maximum"
                          v-model="maxamountforrange2"
                          maxlength="11"
                        />
                      </div>
                    </div>
                  </div>

                  <div class="form-group">
                    <label for=""
                      >How would you like to fund this project?</label
                    >
                    <div class="allocation_wrap">
                      <div class="text-justify radio_wrap-3 radio_wrap-2">
                        <div class="form-check form-check-inline">
                          <input
                            class="form-check-input"
                            type="radio"
                            name="fundtype"
                            id="direct"
                            v-model="fundtype"
                            value="fromsafe"
                          />
                          <label
                            class="form-check-label radio-label"
                            for="direct"
                            >Direct Debit From SAFE</label
                          >
                        </div>
                        <div class="form-check form-check-inline">
                          <input
                            class="form-check-input"
                            type="radio"
                            name="fundtype"
                            id="atwill"
                            v-model="fundtype"
                            value="atwill"
                          />
                          <label
                            class="form-check-label radio-label"
                            for="atwill"
                            >Fund at will</label
                          >
                        </div>
                      </div>
                      <!-- <div
                            class="form-group pl-4 pr-4"
                            v-if="fundtype === 'fromsafe'"
                          >
                            <input
  
                              type="number"
                              class="form-control"
                              id="directDebit"
                              name="fixedamount"
                              placeholder="Direct debit amount"
                            />
                          </div> -->
                    </div>
                  </div>

                  <div class="form-group" v-if="fundtype != 'atwill'">
                    <label for="date">Select Frequency</label>
                    <select
                      class="form-control"
                      id="projecttype"
                      v-model="modeofsafing"
                    >
                      <option selected disabled :value="null"
                        >--Select--</option
                      >
                      <option
                        v-for="(item, index) in modeOfSafeProject"
                        :key="index"
                        :value="item.id"
                        >{{ item.name }}</option
                      >
                    </select>
                  </div>

                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="startdate">Start Date</label>
                        <input
                          type="date"
                          class="form-control"
                          id="startdate"
                          v-model="startdate"
                        />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="enddate">End Date</label>
                        <input
                          :disabled="startdate === '' || startdate === null"
                          type="date"
                          class="form-control"
                          id="enddate"
                          v-model="enddate"
                          @change="calculateDate"
                        />
                      </div>
                    </div>
                  </div>
                </form>
                <!-- <div class="trigger-bittons">
                  <button class="btn continue-later">Edit project</button>
                  <button class="btn next" @click="step = 2">Next</button>
                  <button class="btn submit d-none">Submit</button>
                </div> -->
              </div>
              <div class="col-md-4">
                <div class="goal_target2">
                  <div class="goal_holder2">
                    <img src="../assets/images/target.svg" alt="goal." />
                    <span>Goal</span>
                    <h1 v-if="amountType === 'fixed'">
                      <small v-if="amountforfixed != ''">&#8358;</small
                      >{{ Number(amountforfixed).toLocaleString() }}
                    </h1>
                    <h1 v-else>
                      <small v-if="maxamountforrange != ''">&#8358;</small
                      >{{ Number(maxamountforrange).toLocaleString() }}
                    </h1>
                  </div>
                  <div
                    class="breakdown-amount"
                    v-if="
                      (startdate != null &&
                        enddate != null &&
                        startdate != '' &&
                        enddate != '' &&
                        amountforfixed2 > !0) ||
                        monthly > 2
                    "
                  >
                    <h6>Breakdown</h6>
                    <div class="row m-0" style="background: #F8F8F8;">
                      <div class="col p-0">
                        <small class="duration">
                          Daily
                        </small>
                      </div>
                      <div class="col p-0">
                        <small
                          class="duration-amount"
                          v-if="amountType === 'fixed'"
                        >
                          &#8358;{{
                            Number(amountforfixed / daily).toLocaleString()
                          }}
                        </small>
                        <small
                          class="duration-amount"
                          v-if="amountType === 'range'"
                        >
                          &#8358;{{
                            Number(maxamountforrange / daily).toLocaleString()
                          }}
                        </small>
                      </div>
                    </div>
                    <div class="row m-0">
                      <div class="col p-0">
                        <small class="duration">
                          Weekly
                        </small>
                      </div>
                      <div class="col p-0">
                        <small
                          class="duration-amount"
                          v-if="amountType === 'fixed'"
                        >
                          &#8358;{{
                            Number(amountforfixed / weekly).toLocaleString()
                          }}
                        </small>
                        <small
                          class="duration-amount"
                          v-if="amountType === 'range'"
                        >
                          &#8358;{{
                            Number(maxamountforrange / weekly).toLocaleString()
                          }}
                        </small>
                      </div>
                    </div>
                    <div class="row m-0" style="background: #F8F8F8">
                      <div class="col p-0">
                        <small class="duration">
                          Monthly
                        </small>
                      </div>
                      <div class="col p-0">
                        <small
                          class="duration-amount"
                          v-if="amountType === 'fixed'"
                        >
                          &#8358;{{
                            Number(amountforfixed / monthly).toLocaleString()
                          }}
                        </small>
                        <small
                          class="duration-amount"
                          v-if="amountType === 'range'"
                        >
                          &#8358;{{
                            Number(maxamountforrange / monthly).toLocaleString()
                          }}
                        </small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="trigger-bittons">
              <button
                class="btn continue-later"
                @click="step = 1"
                v-if="step == 2 && detailsProject.submitted == 'YES'"
              >
                Back
              </button>
              <button
                class="btn continue-later"
                v-if="step == 2 && detailsProject.submitted == 'NO'"
                @click="saveDataToContinue"
              >
                Continue later
                <i
                  class="fa fa-spinner fa-spin ml-3 f-12 btn-loader"
                  v-if="saving"
                ></i>
              </button>
              <button
                class="btn continue-later"
                @click="nextStep()"
                v-if="step == 1"
              >
                Next
              </button>
              <button class="btn next" @click="editProject">
                Edit
                <i
                  class="fa fa-spinner fa-spin ml-3 f-12 btn-loader"
                  v-if="loader"
                ></i>
              </button>
              <p
                class="previous-text"
                @click="step = 1"
                v-if="step == 2 && detailsProject.submitted == 'NO'"
              >
                Back to step 1
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SmallNav from "./SmallNav";
import { Service } from "../store/service";
const Api = new Service();
import $ from "jquery";
import moment from "moment";
import Vue from "vue";

export default {
  name: "SafeEditProject",
  components: {
    SmallNav
  },
  data() {
    return {
      loader: false,
      saving: false,
      amountType: "fixed",
      fundtype: "fromsafe",
      modeOfSafeProject: "",
      daysofweek: "",
      futherInfo: "",
      modeofsafing: "",
      projectdetails: "",
      projecttypeid: "",
      startdate: "",
      enddate: "",
      amountforfixed: "" || 0,
      amountforfixed2: "" || 0,
      title: "",
      maxamountforrange: "" || 0,
      minamountforrange: "" || 0,
      maxamountforrange2: "" || 0,
      minamountforrange2: "" || 0,
      projectTypes: [],
      user: "",
      daily: "",
      monthly: "",
      weekly: "",
      serverDateTime: "",
      saveDataServer: "",
      requestHandlerOption: "no",
      projecthandler: "",
      step: 1,
      activeStepper: "",
      activeStepper2: "",
      detailsProject: "",
      openOption: false,
      handlerLoader: false,
      handlerServiceId: "",
      prohandler: "",
      partnerphone: "",
      partneremail: "",
      partnername: "",
      partnerservice: "",
      partners: [],
      handlerId: "",
      serviceId: "",
      servicesPartner: [],
      specifytype: ""
    };
  },
  mounted() {
    this.user = JSON.parse(localStorage.getItem("shelta-user"));
    this.fetchModeOfProjectForDebit();
    this.fetchProjectTypes();
    this.detailsProject = Api.getProjectDetails();
    if (
      this.detailsProject.id === undefined ||
      this.detailsProject.id === null
    ) {
      this.$router.push({
        path: `/dashboard/safeprojects`
      });
    } else {
      this.getSavedData();
      // setTimeout(() => {
      //   this.getSavedData();
      // }, 5000);
      this.activeStepper2 = "active2";
      this.activeStepper = "active";
    }
    if (localStorage.getItem("_safeprojectdata")) {
      this.getProjectData();
    }
    if (this.startdate != "" && this.enddate != "") {
      this.calculateDate();
    }
    this.getServerDateTime();
    // this.getCurrentDay();
    // this.getSavedData();
  },
  // beforeMount() {
  //   this.getCurrentDay()
  // },
  // created() {
  //   // this.$root.$refs.A = this;
  //   // this.getCurrentDay()
  // },
  methods: {
    runOption(event) {
      const inputBox = this.$refs.openPanel;
      // console.log("reffffff", inputBox);
      this.openOption = true;
      if (!inputBox.contains(event.target)) {
        this.openOption = false;
      }
    },
    selectPanel(name, id) {
      this.prohandler = name;
      this.handlerId = id;
      this.openOption = false;
      this.fetchPartnerServices(id);
    },
    back() {
      this.$router.go(-1);
    },
    getServerDateTime() {
      Api.getRequest(`getservertime`).then(res => {
        // console.log("Server time", res.data.date);
        this.serverDateTime = res.data.date;
      });
    },
    getCurrentDay() {
      const today = new Date().toISOString().split("T")[0];
      document.getElementById("startdate").setAttribute("min", today);
      document.getElementById("enddate").setAttribute("min", today);

      // const current = new Date();
      // current.setMonth(current.getMonth() + 3);
      // const x = new Date(current).toISOString().split("T")[0];
      // document.getElementById("enddate").setAttribute("max", x);
    },
    calculateDate() {
      // Weekly pick
      const oneweek = 1000 * 60 * 60 * 24 * 7;
      const d1 = new Date(this.startdate).getTime();
      const d2 = new Date(this.enddate).getTime();
      const difference = Math.abs(d2 - d1);
      this.weekly = Math.floor(difference / oneweek);
      // console.log("weeks", Math.floor(difference / oneweek), this.weekly);
      // return Math.floor(difference / oneweek);

      // Daily pick
      const day = 1000 * 60 * 60 * 24;
      const date1 = new Date(this.startdate).getTime();
      const date2 = new Date(this.enddate).getTime();
      const difference2 = Math.abs(date2 - date1);
      this.daily = Math.floor(difference2 / day);
      // console.log("Days", Math.floor(difference2 / day), this.daily);
      // return Math.floor(difference_ms / ONE_WEEK);

      // Monthly pick
      let months;
      months =
        (new Date(this.enddate).getYear() -
          new Date(this.startdate).getYear()) *
        12;
      months -= new Date(this.startdate).getMonth();
      months += new Date(this.enddate).getMonth();
      this.monthly = months <= 0 ? 0 : months;

      // if (this.startdate === this.enddate) {
      //   this.$store.commit("setApiFailed", "Start date and end date should not be the same");
      // }
    },
    fetchProjectTypes() {
      Api.getRequest(`fetchsafeprojecttypes`).then(res => {
        // console.log("project Types", res);
        if (res.data.success) {
          this.projectTypes = res.data.safeprojecttypes;
        } else {
          this.$store.commit("setApiFailed", res.data.error);
        }
      });
    },
    fetchModeOfProjectForDebit() {
      Api.getRequest(`fetchmodeofsafingforprojects`).then(res => {
        // console.log("Mode of project debit", res);
        if (res.data.success) {
          this.modeOfSafeProject = res.data.modes;
        } else {
          this.$store.commit("setApiFailed", res.data.error);
        }
      });
    },
    revertHandler() {
      if (this.requestHandlerOption == "no") {
        this.handlerId = "";
        this.handlerServiceId = "";
        this.prohandler = "";
        // console.log("handler", this.prohandler);
      }
    },
    async addNewHandler() {
      this.handlerLoader = true;
      try {
        await Api.postRequest(`createpartnerwhilecreatingproject`, {
          phone: this.partnerphone,
          email: this.partneremail,
          partnername: this.partnername,
          service: this.partnerservice
        })
          .then(res => {
            this.handlerLoader = false;
          })
          .catch(err => {
            console.log(err);
            this.handlerLoader = false;
          });
      } finally {
        // console.log("closed");
      }
    },
    async fetchPartners() {
      try {
        await Api.getRequest(`fetchpartersforprojecthandling`)
          .then(res => {
            if (res.data.success) {
              this.partners = res.data.partners;
            }
            if (res.data.error) {
              this.$store.commit("setApiFailed", res.data.error);
            }
          })
          .catch(err => {
            console.log(err);
          });
      } finally {
        // console.log("closed");
      }
    },
    async fetchPartnerServices(id) {
      try {
        await Api.getRequest(`getservicesbypartnerid/${id}`)
          .then(res => {
            // console.log("parners services", res);
            if (res.data.success) {
              this.servicesPartner = res.data.partnerservices;
            }
            if (res.data.error) {
              this.$store.commit("setApiFailed", res.data.error);
            }
          })
          .catch(err => {
            console.log(err);
          });
      } finally {
        // console.log("closed");
      }
    },
    nextStep() {
      if (this.projecttypeid == 0) {
        if (this.specifytype == "") {
          return this.$store.commit("setApiFailed", "Specify project type");
        }
      }
      if (this.requestHandlerOption == "yes") {
        if (this.handlerServiceId === "" || this.handlerId === "") {
          return this.$store.commit("setApiFailed", "Fill required fields");
        }
        if (this.projectdetails === "" || this.projecttypeid === "") {
          return this.$store.commit("setApiFailed", "Fill required fields");
        } else {
          this.activeStepper = "active";
          this.step = 2;
          setTimeout(() => {
            this.getCurrentDay();
          }, 1000);
        }
      } else if (this.requestHandlerOption == "no") {
        if (this.projectdetails === "" || this.projecttypeid === "") {
          return this.$store.commit("setApiFailed", "Fill required fields");
        } else {
          this.activeStepper = "active";
          this.step = 2;
          setTimeout(() => {
            this.getCurrentDay();
          }, 1000);
        }
      }
    },
    async editProject() {
      this.loader = true;
      if (this.amountType === "fixed") {
        if (this.amountforfixed === "") {
          this.loader = false;
          return this.$store.commit("setApiFailed", "Fill required fields");
        }
      }
      if (this.amountType === "range") {
        if (this.maxamountforrange === "") {
          this.loader = false;
          return this.$store.commit("setApiFailed", "Fill required fields");
        }
      }
      if (this.fundtype === "fromsafe") {
        if (this.modeofsafing === "") {
          this.loader = false;
          return this.$store.commit("setApiFailed", "Fill required fields2");
        }
      }
      if (
        this.title === null ||
        // this.modeofsafing === "" ||
        this.startdate === null ||
        this.enddate === null ||
        this.projectdetails === null
      ) {
        this.loader = false;
        return this.$store.commit("setApiFailed", "Fill required fields");
      }
      if (parseInt(this.minamountforrange) > parseInt(this.maxamountforrange)) {
        console.log("min", this.minamountforrange, "Max", this.maxamountforrange)
        this.loader = false;
        return this.$store.commit(
          "setApiFailed",
          "Minimum range can't be greater than maximum range"
        );
      }
      if (this.monthly < 3) {
        this.loader = false;
        return this.$store.commit(
          "setApiFailed",
          "Project end date must be more that three months"
        );
      }
      // let submitOption;
      // if (this.saveDataServer.project == null) {
      //   submitOption = `createnewproject`;
      // } else {
      //   submitOption = `finalsubmitsaveddataforproject`;
      // }
      try {
        if (this.amountType === "fixed") {
          // const formattedAmount1 = this.amountforfixed.split(",").join("");
          this.maxamountforrange = null;
          this.minamountforrange = null;
        }
        if (this.amountType === "range") {
          this.amountforfixed = null;
        }
        this.activeStepper2 = "active2";
        await Api.postRequest(`editsafeproject`, {
          user_id: this.user.id,
          title: this.title,
          amounttype: this.amountType,
          projecttypeid: this.projecttypeid,
          projectdetails: this.projectdetails,
          modeofsafing: this.modeofsafing,
          fundtype: this.fundtype,
          projectid: this.projectid,
          startdate: this.startdate,
          enddate: this.enddate,
          amountforfixed: Number(this.amountforfixed),
          maxamountforrange: this.maxamountforrange,
          minamountforrange: this.minamountforrange,
          specifytype: this.specifytype
        })
          .then(res => {
            // console.log("New project", res);
            this.loader = false;
            if (res.data.success) {
              this.$store.commit("setApiSuccess", "Success");
              // localStorage.removeItem("_safeprojectdata");
              this.$router.push({
                path: `/dashboard/safeprojects`
              });
            }
            if (res.data.error) {
              return this.$store.commit("setApiFailed", res.data.error);
            }
          })
          .catch(err => {
            console.log(err);
            this.loader = false;
          });
      } finally {
        // console.log("closed");
      }
    },
    saveDataToContinue() {
      this.saving = true;
      // let saveOption;
      const projectdata = {
        user_id: this.user.id,
        title: this.title,
        projecttypeid: this.projecttypeid,
        projectdetails: this.projectdetails,
        modeofsafing: this.modeofsafing,
        handler_id: this.handlerId,
        service_id: this.handlerServiceId,
        fundtype: this.fundtype,
        startdate: this.startdate,
        enddate: this.enddate,
        projectid: this.projectid,
        specifytype: this.specifytype
      };
      if (this.amountType === "fixed") {
        projectdata.amounttype = this.amountType;
        projectdata.amountforfixed = this.amountforfixed;
      } else {
        projectdata.amounttype = this.amountType;
        (projectdata.maxamountforrange = this.maxamountforrange),
          (projectdata.minamountforrange = this.minamountforrange);
      }
      // if (this.futherInfo == "yes") {
      //   projectdata.futherInfo = "yes";
      //   projectdata.projecttypeid = this.projecttypeid;
      // } else {
      //   projectdata.futherInfo = "no";
      //   projectdata.projecttypeid = this.projecttypeid;
      // }
      // console.log("data", projectdata);
      // localStorage.setItem("_safeprojectdata", JSON.stringify(projectdata));
      Api.postRequest(`saveforlaterothertimes`, projectdata)
        .then(res => {
          // console.log("saved data", res);
          this.saving = false;
          if (res.data.success) {
            this.$store.commit(
              "setApiSuccess",
              "Data saved, you can continue later"
            );
            this.$router.push({
              path: `/dashboard/safeprojects`
            });
            // this.getSavedData();
          }
          if (res.data.error) {
            this.$store.commit("setApiFailed", res.data.error);
          }
        })
        .catch(err => {
          console.log(err);
          this.saving = false;
        });
    },
    getSavedData() {
      console.log("data to edit", this.detailsProject);
      const x = Math.trunc(this.detailsProject.amountforfixed);
      const y = x.toString();
      const max = Math.trunc(this.detailsProject.maxamountforrange);
      const ca = max.toString();
      const min = Math.trunc(this.detailsProject.minamountforrange);
      const da = min.toString();
      this.title = this.detailsProject.title;
      this.projecttypeid = this.detailsProject.projecttypeid;
      this.projectdetails = this.detailsProject.projectdetails;
      this.amountforfixed2 = y;
      // this.$refs.fixamount.focus();
      this.maxamountforrange = ca;
      this.minamountforrange = da;
      this.modeofsafing = this.detailsProject.modeofsafing;
      this.fundtype = this.detailsProject.fundtype;
      this.amountforfixed = this.detailsProject.amountforfixed;
      this.futherInfo = this.detailsProject.futherInfo;
      this.startdate = this.detailsProject.startdate;
      this.enddate = this.detailsProject.enddate;
      this.projectid = this.detailsProject.id;
      this.specifytype = this.detailsProject.specifytype;
      //   this.maxamountforrange = this.detailsProject.maxamountforrange;
      //   this.minamountforrange = this.detailsProject.minamountforrange;
      this.calculateDate();
      this.fetchPartners();
    }
    // saveDataToContinue() {
    //   this.saving = true;
    //   let saveOption;
    //   const projectdata = {
    //     user_id: this.user.id,
    //     title: this.title,
    //     projecttypeid: this.projecttypeid,
    //     projectdetails: this.projectdetails,
    //     modeofsafing: this.modeofsafing,
    //     fundtype: this.fundtype,
    //     startdate: this.startdate,
    //     enddate: this.enddate
    //   };
    //   if (this.amountType === "fixed") {
    //     projectdata.amounttype = this.amountType;
    //     projectdata.amountforfixed = this.amountforfixed;
    //   } else {
    //     projectdata.amounttype = this.amountType;
    //     (projectdata.maxamountforrange = this.maxamountforrange),
    //       (projectdata.minamountforrange = this.minamountforrange);
    //   }
    //   if (this.saveDataServer.project == null) {
    //     saveOption = `saveforlaterfirsttime`;
    //   } else {
    //     saveOption = `saveforlaterothertimes`;
    //     projectdata.projectid = this.projectid;
    //   }
    //   if (this.futherInfo == "yes") {
    //     projectdata.futherInfo = "yes";
    //     projectdata.projecttypeid = this.projecttypeid;
    //   } else {
    //     projectdata.futherInfo = "no";
    //     projectdata.projecttypeid = this.projecttypeid;
    //   }
    //   // console.log("data", projectdata);
    //   // localStorage.setItem("_safeprojectdata", JSON.stringify(projectdata));
    //   Api.postRequest(`${saveOption}`, projectdata)
    //     .then(res => {
    //       this.saving = false;
    //       if (res.data.success) {
    //         this.$store.commit(
    //           "setApiSuccess",
    //           "Data saved, you can continue later"
    //         );
    //         this.getSavedData();
    //       }
    //       if (res.data.error) {
    //         this.$store.commit("setApiFailed", res.data.error);
    //       }
    //     })
    //     .catch(err => {
    //       console.log(err);
    //       this.saving = false;
    //     });
    // }
  },
  watch: {
    amountforfixed2: function(newValue) {
      const result = newValue
        .replace(/\D/g, "")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      Vue.nextTick(() => (this.amountforfixed2 = result));
      this.amountforfixed = this.amountforfixed2.split(",").join("");
    },
    maxamountforrange2: function(newValue) {
      const result = newValue
        .replace(/\D/g, "")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      Vue.nextTick(() => (this.maxamountforrange2 = result));
      this.maxamountforrange = this.maxamountforrange2.split(",").join("");
    },
    minamountforrange2: function(newValue) {
      const result = newValue
        .replace(/\D/g, "")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      Vue.nextTick(() => (this.minamountforrange2 = result));
      this.minamountforrange = this.minamountforrange2.split(",").join("");
    },
    immediate: true
  },
  // computed: {
  //   class() {
  //     return this.maxamountforrange2 === this.detailsProject.maxamountforrange;
  //   }
  // },
  filters: {
    moment: function(date) {
      return moment(date).format("MMMM Do YYYY, h:mm:ss a");
    }
  }
};
</script>

<style lang="scss" scoped>
$primary: #0033ea;
$secondary: #ffffff;
$yellow: #ffb100;
$tatiary: #a2b0e3;
$black: #000000;
$sheltaBlack: #262626;
$limeGreen: #3ec250;

@font-face {
  font-family: "Lato-Bold";
  src: local("Lato-Bold"),
    url("../assets/fonts/Lato/Lato-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "Lato";
  src: local("Lato-Regular"),
    url("../assets/fonts/Lato/Lato-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Lato-light";
  src: local("Lato-Light"),
    url("../assets/fonts/Lato/Lato-Light.ttf") format("truetype");
}
@font-face {
  font-family: "Lato-bold-Italic";
  src: local("Lato-BlackItalic"),
    url("../assets/fonts/Lato/Lato-BlackItalic.ttf") format("truetype");
}
@font-face {
  font-family: "Lato-thin-Italic";
  src: local("Lato-ThinItalic"),
    url("../assets/fonts/Lato/Lato-ThinItalic.ttf") format("truetype");
}

@font-face {
  font-family: "Gotham";
  src: local("Gotham"),
    url("../assets/fonts/shelta_fonts/Gotham\ Book.otf") format("truetype");
}

.safe_text_holder {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}
.safe_text {
  img {
    background: #3059eb;
    border-radius: 3px;
    padding: 5px;
  }
  font-family: Gotham;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
  color: $black;
  span {
    color: #1765ca;
    cursor: pointer;
  }
}

.holder {
  padding-bottom: 20px;
}
.projects_link {
  h6 {
    background: #3059eb;
    color: $secondary;
    padding: 5px 10px;
    font-family: Gotham;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 11px;
    border-radius: 4px;
    cursor: pointer;
    img {
      padding-right: 5px;
    }
  }
}

// New form for prjects start
.stepper {
  margin-bottom: 22px;
  h5 {
    font-family: Lato;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    margin-top: 15px;
    text-align: left;
    color: $sheltaBlack;
    margin-bottom: 0;
    cursor: pointer;
    width: fit-content;
  }
  h6 {
    font-family: Lato;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: $sheltaBlack;
    text-align: left;
    cursor: pointer;
    width: fit-content;
  }
}
.stepper-1 {
  height: 2px;
  background: #d5d8dd;
  // background: blue;
  border-radius: 50px;
}
.stepper-2 {
  height: 2px;
  background: #d5d8dd;
  // background: red;
  border-radius: 50px;
}
.activeStep {
  background: $primary !important;
}

.activeStepText {
  color: $primary !important;
}

.trigger-bittons {
  // text-align: right;
  padding-top: 40px;
  padding-bottom: 100px;
  button {
    font-family: Lato;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 125%;
    box-shadow: none;
    outline: 0;
  }
  .continue-later {
    margin-right: 28px;
    width: 133px;
    height: 48px;
    border-radius: 6px;
    border: 1px solid $primary;
    color: $primary;
  }
  .next {
    width: 113px;
    height: 48px;
    background: $primary;
    border-radius: 6px;
    color: $secondary;
  }
}
.previous-text {
  padding-top: 30px;
  text-decoration: underline;
  color: $sheltaBlack;
  font-size: 20px;
  cursor: pointer;
  width: fit-content;
  margin: auto;
}

.add-handler-btn {
  button {
    box-shadow: none;
    outline: 0;
    background: $primary;
    color: $secondary;
    font-family: Gotham;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    height: 45px;
  }
}
// New form for projects ends

.form_holder {
  background: #ffffff;
  border: 1px solid #e5e5e5;
  border-radius: 10px;
  padding-top: 40px;
}

.project_info_text {
  h5 {
    font-family: Lato;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    text-align: left;
    color: $primary;
    padding: 15px 0 0 15px;
  }
}

input[type="radio"] {
  // transform:scale(1.5);
  width: 20px;
  height: 20px;
}

input[type="select"],
inpute[type="text"] {
  height: 39.68px;
  border: 0.7px solid #e5e5e5;
  border-radius: 5px;
}

.allocation_wrap {
  border: 0.7px solid #e5e5e5;
  border-radius: 5px;
}
.radio_wrap {
  border: 0.7px solid #e5e5e5;
  border-radius: 5px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.radio_wrap-2 {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.range_input {
  display: flex;
  // padding: 0 !important;
  input {
    margin: 0 10px;
    // border: 1px solid $primary !important;
    // height: 27.68px !important;
    margin-top: 15px;
  }
}

.info-tooltip {
  background: #d9d9d9;
  color: $primary;
  padding: 4px 10px;
  color: #fff;
  border-radius: 3px;
  position: absolute;
  top: 44px;
  right: 0px;
}
small[title]::after {
  background: red;
}

.tooltiptext {
  visibility: hidden;
  width: 225px;
  background: #f2f5fe;
  box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  font-family: Lato;
  font-style: italic;
  font-weight: 300;
  font-size: 12px;
  line-height: 19px;
  display: flex;
  align-items: center;
  letter-spacing: -0.05em;
  color: $black;
  text-align: left;
  padding: 10px 10px;
  position: absolute;
  z-index: 1;
  top: 92%;
  right: 0;
  margin-left: -60px;
}

.info-tooltip + .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent black transparent;
}

.info-tooltip:hover + .tooltiptext {
  visibility: visible;
}

.radio-label {
  font-family: Lato;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 30px;
  color: $black;
  display: flex;
  box-shadow: none;
  outline: 0;
}
.form-group > label {
  font-family: Lato;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 30px;
  display: flex;
  color: #57595a;
}
.form-group {
  select,
  input {
    height: 39.68px;
    border: 0.7px solid #e5e5e5;
    border-radius: 5px;
    box-shadow: none;
    outline: 0;
    font-family: Lato;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 30px;
    color: $sheltaBlack;
    // text-transform: capitalize;
  }
  textarea {
    // text-transform: lowercase;
    border: 0.7px solid #e5e5e5;
    border-radius: 5px;
    box-shadow: none;
    outline: 0;
    font-family: Lato;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 30px;
    color: $sheltaBlack;
  }
}

.panel-input {
  position: relative;
  input {
    padding-right: 30px;
  }
  span {
    position: absolute;
    right: 5px;
    top: 46px;
    cursor: pointer;
    i {
      font-size: 11px;
    }
  }
}
.option-list {
  border: 0.7px solid #e5e5e5;
  border-top: 0;
  li {
    font-family: Lato;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 30px;
    color: $sheltaBlack;
    list-style-type: none;
    cursor: pointer;
    text-align: left;
    padding: 0 15px;
  }
  li:hover {
    background: $primary;
    color: $secondary;
  }
  li:nth-child(even) {
  }
  li:nth-child(odd) {
  }
}
.handler-list {
  max-height: 150px;
  overflow-y: scroll;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
.activeHandler {
  background: $primary !important;
  color: $secondary !important;
}
.handler-list::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.add-project-handler-trigger {
  p {
    color: $primary;
    border-top: 0.7px solid #e5e5e5;
    margin-bottom: 15px;
    text-align: left;
    padding: 15px 10px 0 30px;
    font-family: Lato;
    font-size: 16px;
    cursor: pointer;
    position: relative;
    small {
      padding: 0;
      font-size: 22px;
      position: absolute;
      left: 11px;
      top: 10px;
    }
  }
}

.addHandlerModalContent {
  border-radius: 20px 20px 0px 0px !important;
}
.closeProjectHandler {
  display: flex;
  justify-content: space-between;
  padding: 10px 15px;
  align-items: baseline;
  background: #f0f6ff;
  border-radius: 20px 20px 0px 0px;
  h6 {
    font-family: Gotham;
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 30px;
    color: $black;
  }
  small {
    font-family: Gotham;
    padding-left: 1px;
    text-align: center;
    // background: #c4c4c4;
    color: $black;
    font-size: 20px;
    // border-radius: 50px;
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
}

.pro_details:first-letter {
  text-transform: capitalize !important;
}
.sc-btn {
  margin-top: 20px;
  margin-bottom: 10px;
  background: $primary;
  color: $secondary;
  font-family: Lato;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 30px;
  box-shadow: none;
  outline: 0;
}
.submit-btn {
  margin-top: 20px;
  margin-bottom: 10px;
  background: $yellow;
  color: $black;
  font-family: Lato;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 30px;
  box-shadow: none;
  outline: 0;
  // margin-left: 10px;
}

.or {
  padding: 0 5px;
  position: relative;
  top: 6px;
  font-family: Lato;
  font-style: normal;
  font-size: 12px;
}

.add_project_option {
  // margin-top: 40px;
}

.summary_table {
  text-align: left;
  td {
    border-top: none;
    font-family: "Lato";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    // line-height: 40px;
    color: $black;
  }
  .bold-text {
    font-weight: bold;
  }
}
.break_down_text {
  padding: 10px 10px;
  font-family: Lato;
  font-style: italic;
  font-weight: 700;
  font-size: 14px;
  line-height: 30px;
  display: flex;
  align-items: center;
  color: $primary;
  margin-bottom: 0;
}

.breakdown_target {
  border: 0.7px solid #e5e5e5;
  border-radius: 5px;
  margin-bottom: 20px;
}

.goal_target {
  background: rgba(229, 229, 229, 0.2);
  // border: 0.7px solid #e5e5e5;
  // border-radius: 0px 5px 5px 0px;
  height: 100%;
  padding: 20px;
}

.goal_holder {
  padding-top: 45px;
  text-align: left;
  span {
    font-family: Lato;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 30px;
    color: $black;
    padding-left: 5px;
  }
  h1 {
    font-family: Lato;
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 100%;
    color: $black;
    overflow-wrap: break-word;
    text-align: left;
  }
}

.goal_target2 {
  // height: 100%;
  padding: 0 20px 20px;
}
.goal_holder2 {
  text-align: left;
  border: 1px solid #e2e8f0;
  border-radius: 10px 10px 0px 10px;
  padding: 29px 21px;
  span {
    font-family: Lato;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 30px;
    color: $black;
    padding-left: 5px;
  }
  h1 {
    font-family: Lato;
    font-style: normal;
    // font-size: 30px;
    line-height: 100%;
    font-weight: 700;
    font-size: 37px;
    color: $primary;
    overflow-wrap: break-word;
    text-align: left;
    padding-top: 12px;
    small {
      font-weight: 700;
      font-size: 37px;
    }
  }
}
.breakdown-amount {
  margin-top: 40px;
  border: 1px solid #e2e8f0;
  border-radius: 10px 10px 0px 10px;
  padding-top: 16px;
  // padding-bottom: 10px;
  h6 {
    font-family: Lato;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 125%;
    color: $sheltaBlack;
    margin-bottom: 8px;
    text-align: left;
    padding-left: 8px;
  }
}

.duration,
.duration-amount {
  font-family: Lato;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: $sheltaBlack;
  padding: 8px;
}

.duration {
  display: flex;
  justify-content: left;
}
.duration-amount {
  display: flex;
  justify-content: right;
}

::-webkit-input-placeholder {
  /* Edge */
  font-family: Lato;
  font-style: italic;
  font-weight: 400;
  font-size: 12px;
  line-height: 30px;
  color: #494747;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  font-family: Lato;
  font-style: italic;
  font-weight: 400;
  font-size: 12px;
  line-height: 30px;
  color: #494747;
}

::placeholder {
  font-family: Lato;
  font-style: italic;
  font-weight: 400;
  font-size: 12px;
  line-height: 30px;
  color: #494747;
}
/* Chrome, Safari, Edge, Opera */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

@media only screen and (max-width: 599px) {
  .range_input {
    padding: 0 !important;
  }
  .goal_holder {
    padding-top: 0;
  }
  .radio_wrap-3 {
    display: block;
    margin-left: 15px;
    text-align: left;
  }
  .trigger-bittons {
    .continue-later {
      margin-right: 11px;
    }
  }
}
</style>
